import ChatBot from "./chat/ChatBot.js"
import SignInWidget, { SignInScreen } from "./SignIn.js"
import {createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useRef, useState } from "react";

import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#604CA5",
    },
    // secondary: {
    //   //main: "#E75480"
    //   //main: "#FF5733"
    // }
  },
  typography: {
    fontFamily: [
      '"Lato"',
      '"sans-serif"',
    ],
    color: 'black',
    h3: {
      "fontWeight": 600,
      "fontSize": '36px'
    },
    h1: {
      "fontWeight": 650,
      "fontSize": '48px'
    },
    cardText: {
      "fontSize":'90px',
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "*::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    }
  }
})

function handleRestart(webchatRef){
  if (webchatRef.current && webchatRef.current.sendMessage) {
    var payload = "/restart"
    webchatRef.current.sendMessage(payload);
  }
}

function handleEnd(webchatRef){
  if(webchatRef.current && webchatRef.current.sendMessage) {
    var payload = "/end"
    webchatRef.current.sendMessage(payload);
  }
}

const prospects = [
  {
    id: 1,
    //photo: na_male_1,
    name: "Mathew",
    description: "Mathew is a social buyer with a high level of agreeableness, making his interactions largely rapport driven. While he can be talkative at times, his questioning style is friendly and he demonstrates a high level of knowledge and interest in the conversations.",
    gender: "Male"
  },
]

function SellGPT(){
  const webchatRef = useRef(null);
  const height = "94vh"
  const [loginPopup, setLoginPopup] = useState(false)

  const gradStyle = {
    "-webkit-background-clip": "text",
    "color": "transparent",
    "--tw-gradient-to": "#F50057",
    "--tw-gradient-from": "#604CA5",
    "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
    "backgroundImage": "linear-gradient(to right,var(--tw-gradient-stops))"
  }

  function loginPopupCallback(val){
    if(loginPopup !== val) setLoginPopup(val)
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container style={{marginTop: "5px"}}>
        <Grid item xs={12} md={6}>
          <Card elevation={6} style={{margin: "0 10px 10px", padding: "5px", borderRadius: "10px", minHeight: height, overflowY: "auto", alignItems: "center", alignContent: "center", display: "flex", flexDirection: "column" }}>
            <CardHeader style={{"marginLeft": "auto"}} action={<SignInWidget loginPopupCallback={loginPopupCallback}/>} />
            <CardContent>
              <center>
              <Typography variant="h1">
                <b>Welcome to <Typography style={gradStyle} display="inline" variant="h1"><b>SellGPT</b></Typography></b>
              </Typography>
              &nbsp;
              <Typography variant="h6" display="inline" >
                powered by <a href="https://letsaspiro.com">Aspiro</a>
              </Typography>
              <Typography variant="h6">
                <br />
                SellGPT is a sales roleplay product where you can practice selling the product of your choice to a wide variety of buyer personas. Subsequently, it can evaluate your roleplay on a wide range of sales frameworks and skills so that you can identify your strengths and improve on your weaknesses. Let's start selling!
              </Typography>

              <Typography variant="h6">
                <br />
                <b>To start/restart your roleplay, press <Button variant="contained" color="primary" style={{textTransform: 'none'}} onClick={() => {handleRestart(webchatRef)}}>Start</Button></b>
                <br /><br />
                <b>To generate scores based on the ongoing roleplay, press <Button variant="contained" color="primary" style={{textTransform: 'none'}} onClick={() => {handleEnd(webchatRef)}}>Generate Scores</Button></b>
              </Typography>
              </center>
            </CardContent>
            <CardActions style={{marginTop: "auto"}}>
              <i>Want to run roleplays for your own product? <a target="_blank" rel="noreferrer" href="https://letsaspiro.com/#contact-section">Talk to us</a></i>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChatBot ref={webchatRef} height={height} prospectInfo={prospects[0]} loginPopupCallback={loginPopupCallback} />
        </Grid>
      </Grid>

      <Dialog open={loginPopup}
        scroll="body" aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description" maxwidth="md">
        <DialogTitle id="scroll-dialog-title"><b>Create a <span style={gradStyle}>free</span> Account</b></DialogTitle>
        <DialogContent dividers={false}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <span style={{color: 'black'}}>Please create an account to continue with your roleplay</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SignInWidget loginPopupCallback={loginPopupCallback}/>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={SignInScreen} />
        <Route exact path="/" component={SellGPT} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

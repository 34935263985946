import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from './base'

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      // forceSameDevice: false,
      // emailLinkSignIn: function() {
      //   return {
      //     dynamicLinkDomain: 'example.page.link',
      //     // Always true for email link sign-in.
      //     handleCodeInApp: true,
      //     // Whether to handle link in iOS app if installed.
      //     iOS: {
      //       bundleId: 'com.example.ios'
      //     },
      //     // Whether to handle link in Android app if opened in an Android
      //     // device.
      //     android: {
      //       packageName: 'com.example.android',
      //       installApp: true,
      //       minimumVersion: '12'
      //     }
      //   };
      // }
    }
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

function SignInScreen() {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);


  if (!isSignedIn) {
    return (
      <div>
        <center><Typography variant="h6">Login to SellGPT</Typography></center>
        <br />
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </div>
    );
  }
  return (
    <div>
      <br />
      <center>
      <Typography variant="h6">Welcome {firebase.auth().currentUser.displayName} to SellGPT! You are now signed-in!</Typography>
      <br />
      <Typography variant="body1">You can now close this window.</Typography>
      </center>
    </div>
  );
}

function SignInWidget(props){
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const [name, setName] = useState('');
  const [menuToggle, setMenuToggle] = useState(true);

  //Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
      if(user) props.loginPopupCallback(false);
      if(user) setName(user.displayName)
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [props]);

  function modifiedName(name){
    if(name.length <= 7) return name
    else return name.substring(0, 6) + ".."
  }

  function handleLogin(){
    const w = 500
    const h = 800
    const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);

    window.open("/login", 'Sign In', 'width=' + String(w) + ',height=' + String(800) + ',top=' + String(y) + ',left=' + String(x));
  }

  return (
    <>
    {
      !isSignedIn?
      <center>
        <Button variant="contained" color="secondary" style={{textTransform: 'none'}} onClick={() => {handleLogin()}}>Login</Button>
      </center>
      :
      <Button style={{textTransform: 'none', padding: "0px", minHeight: 60}} onClick={() => setMenuToggle(!menuToggle)}>
        <Card style={{border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: "10px"}}>
          <center>
            <CardContent style={{padding: "8px"}}>
              {menuToggle?
              <div style={{display:"flex"}}>
                <Avatar style={{backgroundColor: "#604ca5", marginRight: "2px", width: 35, height: 35}} display="inline">{name[0]}</Avatar>
                <Typography variant="h6" >{modifiedName(name.split(" ")[0])}</Typography>
              </div>
              :
              <Button variant="text" color="secondary" size="large" style={{textTransform: 'none'}} onClick={() => {firebase.auth().signOut()}}><b>Logout</b></Button>
              }
            </CardContent>
          </center>
        </Card>
      </Button>
    }
    </>
  )
}

export default SignInWidget;
export { SignInScreen };

import React from 'react';
import Widget from 'webchat';
import { withTheme } from '@material-ui/core/styles'
import firebase from './../base'
import axios from "axios"

class ChatBot extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isSignedIn: !!firebase.auth().currentUser
    }
    this.storeConversation = this.storeConversation.bind(this)
  }

  componentDidMount() {
    // Listen to the Firebase Auth state and set the local state.
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if(!!user !== this.state.isSignedIn){
        this.setState({
          isSignedIn: !!user
        })
      }
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }

  storeConversation(msgObj, id){
    //dummy value should also be valid email ID for DB constraint
    const email = firebase.auth().currentUser?firebase.auth().currentUser.email: 'guest@guest.com'
    var processedMsgObj = []
    var curIndex = 0;

    //process msgObj
    while(msgObj.size > 0 && curIndex < msgObj._tail.array.length){
      var mapp = msgObj._tail.array[curIndex];
      var msg = mapp.get('text'), sender = mapp.get('sender'), is_hidden = mapp.get('hidden'), vote = mapp.get('vote');

      if(typeof(msg) === "string" && typeof(sender) === "string"){
        if(!is_hidden || is_hidden === false){
          //if is_hidden is not defined, we should still reach here
          processedMsgObj.push({
            "sender": sender,
            "msg": msg,
            "vote": vote,
          })
        }
      }
      curIndex += 1;
    }

    const data = {
      "email": email,
      "socketId": id,
      "msgObj": processedMsgObj
    }

    axios.post(process.env.REACT_APP_BACKEND + '/store_conversation', data)
    .then((response) => {
      console.log("Conversation stored in DB")
    }).catch(error => console.error(error));

    if(!this.state.isSignedIn && msgObj.size >= 17){
      console.log(this.state.isSignedIn)
      this.props.loginPopupCallback(true)
    }
  }

  render() {
    const widgetWidth = 500

    var widgetStyle = {
      display: "inline-block",
      width: widgetWidth,
      maxWidth: "95%",
      height: this.props.height,
      margin: "0px 10px 5px",
    }
    var containerStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }

    console.log("Connecting to rasa server")
    const rasa_address = process.env.REACT_APP_RASA

    var init_payload = '/start_conversation'
    init_payload += '{'
    init_payload += '"prospect_name": "' + this.props.prospectInfo["name"] + '", '
    init_payload += '"prospect_description": "' + this.props.prospectInfo["description"] + '", '
    init_payload += '"seller_name": "' + 'Sidharth' + '", '
    init_payload += '"call_type": "Cold Call"'
    init_payload += '}'

    //imp to pass props this way, otherwise they aren't accessible inside ThemeWidget
    var widget_data = {
      //avatar: this.state.avatar,
      //socketCustomClient: this.props.socketCustomClient,
      initPayload: init_payload,
      socketUrl: rasa_address,
      socketPath: "/socket.io/",
      title: "Mathew",
      subtitle: "Online",
      storage: "session",
      embedded: true,
      //speechRecognition: "en-US",
      ref: this.props.innerRef,
      //retry: null,
      geo: "North America",
      gender: "Male",
      storeConversation: this.storeConversation,
      gptOnly: true,
      socketIdCallback: this.updateSocketId
    }

    const ThemeWidget = withTheme((props) => {
      return (
        <Widget
          userBackgroundColor={props.theme.palette.primary.main}
          mainColor={props.theme.palette.primary.main}
          //avatar={widget_data["avatar"]}
          //socketCustomClient={widget_data["socketCustomClient"]}
          initPayload={widget_data["initPayload"]}
          socketUrl={widget_data["socketUrl"]}
          socketPath={widget_data["socketPath"]}
          title={widget_data["title"]}
          subtitle={widget_data["subtitle"]}
          embedded={widget_data["embedded"]}
          //speechRecognition={widget_data["speechRecognition"]}
          ref={widget_data["ref"]}
          //retry={widget_data["retry"]}
          geo={widget_data["geo"]}
          gender={widget_data["gender"]}
          storeConversation={widget_data["storeConversation"]}
          gptOnly={widget_data["gptOnly"]}
          params={{"storage": widget_data["storage"]}}
          socketIdCallback={widget_data["socketIdCallback"]}
        />
      )
    });

    return (
      <div style={containerStyle}>
        <div style={widgetStyle}>
          <ThemeWidget />
        </div>
      </div>
    )
  }
}

// const mapStateToProps = state => ({
//   email: state.email,
//   role: state.role,
//   avatarInfo: state.avatarInfo
// })

//ref forwarding for class components- https://stackoverflow.com/a/52223103/9160820
const wrapper = React.forwardRef((props, ref) => <ChatBot
  innerRef={ref} {...props}
/>);
export default wrapper;
